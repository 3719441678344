import request from './request';

const classificationService = {
  getAll: (params) =>
    request.get('dashboard/admin/classification/paginate', { params }),
  getAllSeller:(params) =>
  request.get('dashboard/seller/classification/paginate', { params }),
  getById: (id, params) =>
    request.get(`dashboard/admin/classification/${id}`, { params }),
  create: (params) => request.post('dashboard/admin/classification', {}, { params }),
  update: (id, params) =>
    request.patch(`dashboard/admin/classification/${id}`, {}, { params }),
  delete: (id) => request.delete(`dashboard/admin/classification/${id}`),
  setActive: (id) => request.post(`dashboard/admin/classification/active/${id}`),
  getCustomers: (id) =>  request.get(`dashboard/admin/statistics/customer/classification/${id}`),

};

export default classificationService;
