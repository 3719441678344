import React from 'react';
import { Card, Table, Descriptions, Spin, Row } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import numberToPrice from '../../helpers/numberToPrice';
import Barcode from 'react-barcode';

const OrderDetailsInfo = ({ data, activeMenu, loading , componentRef }) => {
  const { t } = useTranslation();
  const { defaultCurrency } = useSelector(
    (state) => state.currency,
    shallowEqual
  );
  // const componentRef = useRef(null);
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const PrintColumns = [
    // {
    //   title: t('id'),
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    // {
    //   title: t('Barcode'),
    //   dataIndex: 'stock',
    //   key: 'stock',
    //   render: (stock) => stock?.product?.barcode,
    // },
    {
      title: t('Barcode'),
      dataIndex: 'stock',
      key: 'stock',
      render: (stock) => (
        <Barcode height={50} width={1.2} value={stock?.product?.barcode} />
      ),
    },
    {
      title: t('product.name'),
      dataIndex: 'stock',
      key: 'stock',
      render: (stock) => stock?.product?.translation?.title,
    },

    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: t('origin.price'),
      dataIndex: 'origin_price',
      key: 'origin_price',
      render: (origin_price, row) =>
        numberToPrice(origin_price * row.quantity, defaultCurrency.symbol),
    },
    {
      title: t('discount'),
      dataIndex: 'discount',
      key: 'discount',
      render: (discount, row) =>
        numberToPrice(discount, defaultCurrency.symbol),
    },

    {
      title: t('total.price'),
      dataIndex: 'total_price',
      key: 'total_price',
      render: (total_price) =>
        numberToPrice(total_price, defaultCurrency.symbol),
    },
  ];
  function calculateProductsPrice() {
    return data?.details.reduce((total, item) => (total += item.price), 0);
  }
  function calculateProductsOriginPrice() {
    return data?.details[0].order_stocks.reduce((total, item) => (total += item.origin_price * item.quantity), 0);
  }
  
  function calculateDeliverydiscount() {
    return data?.details[0].order_stocks.reduce((total, item) => (total += item.discount), 0);
  }

  function calculateTax() {
    return data?.details.reduce((total, item) => (total += item.tax), 0);
  }

  function calculateCoupon() {
    return data?.details.reduce(
      (total, item) => (total += item.coupon?.price),
      0
    );
  }

  function calculateDeliveryPrice() {
    return data?.details.reduce(
      (total, item) => (total += item.delivery_fee),
      0
    );
  }

  return (
    <div className='py-4 order-preview'>
      {loading ? (
        <div className='w-100 text-center'>
          <Spin />
        </div>
      ) : (
      
        <div className='print_modal' ref={componentRef}>
          {' '}
          <Card>
            <Row hidden={loading} className='mb-3'>
              <h1 className='mb-1 font-weight-semibold'>
                {t('invoice')} #{data?.id}
              </h1>
              <Descriptions>
                <Descriptions.Item
                  className='f_18'
                  label={t('client')}
                  span={3}
                >
                  {data?.user?.firstname} {data?.user?.lastname}
                </Descriptions.Item>
                <Descriptions.Item className='f_18' label={t('phone')} span={3}>
                  {data?.user?.phone}
                </Descriptions.Item>
                <Descriptions.Item className='f_18' label={t('email')} span={3}>
                  {data?.user?.email}
                </Descriptions.Item>
                <Descriptions.Item
                  className='f_18'
                  label={t('Delivery location')}
                  span={3}
                >
                  {data?.details[0]?.delivery_type?.translation?.title}
                </Descriptions.Item>
                <Descriptions.Item className='f_18' label={t('Title')} span={3}>
                  {data?.details[0]?.delivery_address?.title}
                </Descriptions.Item>
                <Descriptions.Item
                  className='f_18'
                  label={t('address')}
                  span={3}
                >
                  {data?.details[0]?.delivery_address?.address}
                </Descriptions.Item>
                <Descriptions.Item
                  className='f_18'
                  label={t('created.at')}
                  span={3}
                >
                  {data?.createdAt}
                </Descriptions.Item>
                <Descriptions.Item
                  className='f_18'
                  label={t('payment.type')}
                  span={3}
                >
                  {t(data?.details[0]?.transaction?.payment_system?.tag)}
                </Descriptions.Item>
                <Descriptions.Item
                  className='f_18'
                  label={t('amount')}
                  span={3}
                >
                  {numberToPrice(data?.price, defaultCurrency.symbol)}
                </Descriptions.Item>
                <Descriptions.Item
                  className='f_18'
                  label={t('Number of products')}
                  span={3}
                >
                  {data?.details[0]?.order_stocks.length}
                </Descriptions.Item>
                <Descriptions.Item
                  className='f_18'
                  label={t('cashback')}
                  span={3}
                >
                  {numberToPrice(data?.cashback, defaultCurrency.symbol)}
                </Descriptions.Item>
                <Descriptions.Item
                  className='f_18'
                  label={`${t('coupon')} ${data?.details[0]?.coupon?.name} `}
                  span={3}
                >
                  {data?.details[0]?.coupon?.price}
                </Descriptions.Item>
                <Descriptions.Item
                className='f_18'
              label={`${t('Client Note')} `}
              span={3}
            >
              {data?.note}
            </Descriptions.Item>
              </Descriptions>
            </Row>
            <div className='mt-4'>
              <Table
                columns={PrintColumns}
                dataSource={activeMenu.data?.details[0].order_stocks || []}
                loading={loading}
                rowKey={(record) => record.id}
                pagination={false}
                className='order_table'
              />
            </div>
            <div className='d-flex justify-content-end mt-4'>
              <div className='text-right '>
                <div className='border-bottom'>
                  <p className='mb-2'>
                    <span>{t('sub-total amount before discount')}: </span>
                    {numberToPrice(
                      calculateProductsOriginPrice(),
                      data?.currency?.symbol
                    )}
                  </p>
                  <p>
                    {t('total discount')} :{' '}
                    {numberToPrice(
                      calculateDeliverydiscount(),
                      data?.currency?.symbol
                    )}
                  </p>
                  <p className='mb-2'>
                    <span>{t('sub-total amount after discount')}: </span>
                    {numberToPrice(
                      calculateProductsOriginPrice() - calculateDeliverydiscount(),
                      data?.currency?.symbol
                    )}
                  </p>
                  <p>
                    {t('delivery.price')} :{' '}
                    {numberToPrice(
                      calculateDeliveryPrice(),
                      data?.currency?.symbol
                    )}
                  </p>
                  {/* <p>
                {t('tax')} :{' '}
                {numberToPrice(calculateTax(), data?.currency?.symbol)}
              </p> */}
                  <p>
                    {t('coupon')} :{' '}
                    {numberToPrice(calculateCoupon(), data?.currency?.symbol)}
                  </p>
                </div>
                <h2 className='font-weight-semibold mt-3'>
                  <span className='mr-1'>{t('grand.total')}: </span>
                  {numberToPrice(data?.price, data?.currency?.symbol)}
                </h2>
              </div>
            </div>
          </Card>
        </div>
       
      )}
    </div>
  );
};

export default OrderDetailsInfo;
