import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Select, Switch } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeFromMenu, setMenuData } from '../../redux/slices/menu';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBloggers } from '../../redux/slices/blogger';
import productService from '../../services/product';
import { DebounceSelect } from '../../components/search';
import bloggerService from '../../services/blogger';
import { useTranslation } from 'react-i18next';

const BloggerAdd = () => {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { uuid } = useParams();
  const [error, setError] = useState(null);

  const { defaultLang, languages } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );
  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  const onFinish = (values) => {
    const body = {
      url: values.url,
      ...values,

      'images[0]': image?.name,
      ...Object.assign(
        {},
        ...values.products.map((item, index) => ({
          [`products[${index}]`]: item.value,
        }))
      ),
    };
    setLoadingBtn(true);
    const nextUrl = 'bloggers';
    bloggerService
      .create(body)
      .then(() => {
        toast.success(t('successfully.created'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchBloggers());
      })
      .catch((err) => setError(err.response.data.params, {autoClose: 10000}))

      .finally(() => setLoadingBtn(false));
  };
  function getLanguageFields(data) {
    if (!data) {
      return {};
    }
    const { translations } = data;
    const result = languages.map((item) => ({
      [`title[${item.locale}]`]: translations.find(
        (el) => el.locale === item.locale
      )?.title,
     
    }));
    return Object.assign({}, ...result);
  }

  function fetchProducts(search) {
    const params = {
      search,
      perPage: 10,
    };
    return productService
      .getAll(params)
      .then((res) => formatProducts(res.data));
  }

  
  function formatProducts(data) {
    return data.map((item) => ({
      label: item.translation?.title,
      value: item.id,
    }));
  }

  const handleCancel = () => {
    const nextUrl = 'bloggers';
    dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
    navigate(`/${nextUrl}`);
  };
  return (
    <Card      title={uuid ? t('edit.blogger') : t('add.blogger')}
    className='h-100'>
      <Form
        name='blogger-add'
        layout='vertical'
        onFinish={onFinish}
        form={form}
        initialValues={{ active: true, ...activeMenu.data }}
        className='d-flex flex-column h-100'
      >
        <Row gutter={12}>
        {languages.map((item) => (
                   <Col span={12}>

            <Form.Item
              key={'title' + item.id}
              label={`${t('title')} ${t(item.locale)}`}
              name={`title[${item.locale}]`}
              rules={[
                {
                  required: true,
                  // required: item.locale === defaultLang,
                  message: t('required'),
                },
                
              ]}
              help={
                error
                  ? error[`title.${defaultLang}`]
                    ? error[`title.${defaultLang}`][0]
                    : null
                  : null
              }
              validateStatus={error ? 'error' : 'success'}
              // hidden={item.locale !== defaultLang}
            >
              <Input />
            </Form.Item>
            </Col>
          ))}

          <Col span={12}>
            <Form.Item
              label={t('image')}
              name='image'
              rules={[
                {
                  required: true,
                  message: t('required'),
                },
              ]}
            >
              <ImageUploadSingle
                type='banners'
                image={image}
                setImage={setImage}
                form={form}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('url')} name={'url'}>
              <Input />
            </Form.Item>
          </Col>



          <Col span={24}>
            <Form.Item
              label={t('products')}
              name={'products'}
              rules={[
                {
                  required: true,
                  message: t('required'),
                },
              ]}
            >
              <DebounceSelect
                mode='multiple'
                fetchOptions={fetchProducts}
                debounceTimeout={200}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className='flex-grow-1 d-flex flex-column justify-content-end'>
          <div className='pb-5'>
            <Button type='primary' htmlType='submit' loading={loadingBtn}>
              {t('submit')}
            </Button>
            <Button onClick={handleCancel}>{t('cancel')}</Button>

          </div>
        </div>
      </Form>
    </Card>
  );
};

export default BloggerAdd;
