import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Modal, Tag, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/loading';
import ClassificationService from '../../services/classification';
import numberToPrice from '../../helpers/numberToPrice';
import { map } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
export default function ClassificationShowModal({ id, handleCancel }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { defaultCurrency } = useSelector(
    (state) => state.currency,
    shallowEqual
  );
  const { t } = useTranslation();

  function fetchClassification(id) {
    setLoading(true);
    ClassificationService
      .getCustomers(id)
      .then((res) => setData(res.data))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchClassification(id);
  }, [id]);
  return (
    <Modal
      visible={!!id}
      title={t('customers')}
      onCancel={handleCancel}
      footer={
        <Button type='default' onClick={handleCancel}>
          {t('cancel')}
        </Button>
      }
    >
      {!loading ? (
        <>
         {data.length ? (
          data.map((item, idx) => (
            <div className='w-100 py-3 flex' key={idx}>
              <div className='d-flex avatar'>
                
                <div className='ml-2 avatar-text'>
                  <h5 className='title'>
                    {item.firstname + ' ' + item.lastname}
                  </h5>
                  <div className='text-muted'>{item.phone}</div>
                </div>
              </div>
              <div className='d-flex'>
                <div className='mr-3 text-right'>
                  <span className='text-muted'>{t('orders')}</span>
                  <div className='mb-0 h5 font-weight-bold'>
                    {numberToPrice(
                      item.orders_sum_price,
                      defaultCurrency.symbol
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Empty />
        )}</>
      ) : (
        <Loading />
      )}
    </Modal>
  );
}
