import React, { useContext, useEffect, useState } from 'react';
import { Button, Space, Table, Card, Tabs , Image} from 'antd';
import { useNavigate } from 'react-router-dom';
import {  CloseOutlined ,EditOutlined } from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addMenu, disableRefetch, setMenuData } from '../../redux/slices/menu';
import { useTranslation } from 'react-i18next';
import useDidUpdate from '../../helpers/useDidUpdate';
import { fetchInvoices } from '../../redux/slices/invoice';
import formatSortType from '../../helpers/formatSortType';
// import { clearOrder } from '../../redux/slices/order';
// import { IMG_URL } from '../../configs/app-global';
import getImage from '../../helpers/getImage';
import invoiceservice from '../../services/invoice';
import DeleteButton from '../../components/delete-button';
import { Context } from '../../context/context';
import { toast } from 'react-toastify';
import CustomModal from '../../components/modal';
import InvoiceModal from './invoiceModal';
import { set } from 'lodash';
const { TabPane } = Tabs;

const statuses = ['new', 'completed',"canceled"];

export default function Order() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [ modal , setModal]= useState(false);

  const goToEdit = (row) => {
setModal(true)
setId(row.id)
    // // dispatch(clearOrder());
    // dispatch(
    //   addMenu({
    //     url: `invoice/${row.id}`,
    //     id: 'invoice_edit',
    //     name: t('edit.invoice'),
    //   })
    // );
    // navigate(`/invoice/${row.id}`);
  };
  const InvoiceDelete = () => {
    setLoadingBtn(true);
    invoiceservice
      .update(id, {status:"canceled"})
      .then(() => {
        toast.success(t('successfully.change_status'), {autoClose: 10000});
        dispatch(fetchInvoices({}));
        setIsModalVisible(false);
      })
      .finally(() => setLoadingBtn(false));
  };
  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      // sorter: true,
    },
    {
      title: t('image'),
      dataIndex: 'img',
      key: 'img',
      render: (img, row) => {
        return (
          <Image
            src={getImage(img)}
            alt='img_gallery'
            width={100}
            className='rounded'
            preview
            placeholder
            key={img + row.id}
          />
        );
      },
    },
    {
      title: t('client'),
      dataIndex: 'user',
      key: 'user',
      render: (user) => (
        <div>
          {user.firstname} {user.lastname}
        </div>
      ),
    },

    {
      title: t('created.at'),
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: t('note'),
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: t('options'),
      key: 'options',
      width: '30%',
      render: (data, row) => {
        
        return (
          data.status=='new'&&
          <Space>
            <Button
              type='primary'
              icon={<EditOutlined />}
              onClick={() => goToEdit(row)}
            />
             <DeleteButton
              icon={<CloseOutlined />}
              onClick={() => {
                setId(row.id);
                setIsModalVisible(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { invoices, meta, loading, params } = useSelector(
    (state) => state.invoice,
    shallowEqual
  );
  const { setIsModalVisible } = useContext(Context);
  const [id, setId] = useState(null);
  console.log(invoices,"iiiiiiiiiiiiiiii")
  const data = activeMenu?.data;

  function onChangePagination(pagination, filters, sorter) {
    const { pageSize: perPage, current: page } = pagination;
    const { field: column, order } = sorter;
    const sort = formatSortType(order);
    dispatch(
      setMenuData({
        activeMenu,
        data: { ...data, perPage, page, column, sort },
      })
    );
  }

  useDidUpdate(() => {
    const paramsData = {
      // search: data?.search,
      sort: data?.sort,
      column: data?.column,
      perPage: data?.perPage,
      page: data?.page,
      user_id: data?.userId,
      status:  data?.status||'new',
    };
    dispatch(fetchInvoices(paramsData));
  }, [activeMenu?.data]);

  useEffect(() => {
    if (activeMenu?.refetch) {
      const params = {
        status: data?.status||'new',
      };
      console.log(data,"ddddddddd")
      dispatch(fetchInvoices(params));
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu?.refetch]);

  const handleFilter = (item, name) => {
    dispatch(
      setMenuData({
        activeMenu,
        data: { ...data, [name]: item },
      })
    );
  };

  const onChangeTab = (status) => {
    dispatch(setMenuData({ activeMenu, data: { status } }));
  };

  //   function getInvoiceFile(id) {
  //     setDownloading(id);
  //     exportService
  //       .orderExport(id)
  //       .then((res) => {
  //         download(res, `invoice_${id}.pdf`, 'application/pdf');
  //       })
  //       .finally(() => setDownloading(null));
  //   }

  return (
    <Card title={t('invoices')} extra={<Space></Space>}>
      <Tabs onChange={onChangeTab} type='card' activeKey={data?.status}>
        {statuses.map((item) => (
          <TabPane tab={t(item)} key={item} />
        ))}
      </Tabs>
      <Table
        columns={columns}
        dataSource={invoices}
        loading={loading}
        pagination={{
          pageSize: params.perPage,
          page: params.page,
          total: meta.total,
          defaultCurrent: params.page,
        }}
        rowKey={(record) => record.id}
        // onChange={onChangePagination}
      />
        <CustomModal
        click={InvoiceDelete}
        text={t('delete.invoice')}
        loading={loadingBtn}
      />
      <InvoiceModal
      setModal={setModal}
      id={id}
      modal= {modal}
      />
    </Card>
  );
}
