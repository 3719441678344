import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../../redux/slices/menu';
import discountService from '../../../services/seller/discount';
import { fetchDiscounts } from '../../../redux/slices/discount';
import { DebounceSelect } from '../../../components/search';
import productService from '../../../services/seller/product';
import { useTranslation } from 'react-i18next';
import createImage from '../../../helpers/createImage';
import ImageUploadSingle from '../../../components/image-upload-single';
import brandService from '../../../services/rest/brand';
import categoryService from '../../../services/rest/category';
import { get, isEmpty } from 'lodash';

export default function DiscountEdit() {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [type, setType] = useState('product');
  const [pageSize] = useState(300);
  const [pageCurrent] = useState(1);

  useEffect(() => {
    return () => {
      const values = form.getFieldsValue(true);
      const start = JSON.stringify(values.start);
      const end = JSON.stringify(values.end);
      const data = { ...values, start, end };
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  function fetchDiscount() {
    setLoading(true);
    discountService
      .getById(id)
      .then(({ data }) => {
        const values = {
          price: data.price,
          type: data.type,
          products: data.products?.map((item) => ({
            label: item.translation?.title,
            value: item.id,
          })),
          categories: data.categories?.map((item) => ({
            label: item.translation?.title,
            value: item.id,
          })),
          brands: data.brands?.map((item) => ({
            label: item.translation?.title,
            value: item.id,
          })),
          start: moment(data.start, 'YYYY-MM-DD'),
          end: moment(data.end, 'YYYY-MM-DD'),
          image: createImage(data.img),
        };
        form.setFieldsValue(values);
        setImage(createImage(data.img));
      })
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  }

  useEffect(() => {
    if (activeMenu.refetch) {
      fetchDiscount();
    }
  }, [activeMenu.refetch]);

  const onFinish = (values) => {
    const body = {
      price: values.price,
      type: values.type,
      products: values.products?.map((item) => item.value),
      brands: values.brands?.map((item) => item.value),
      categories: values.categories?.map((item) => item.value),

      start: values.start
        ? moment(values.start).format('YYYY-MM-DD')
        : undefined,
      end: values.end ? moment(values.end).format('YYYY-MM-DD') : undefined,
      flash_sale: values.flash_sale,
      // images: [image?.name],
    };
    setLoadingBtn(true);
    const nextUrl = 'seller/discounts';
    discountService
      .update(id, body)
      .then(() => {
        toast.success(t('successfully.updated'));
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchDiscounts({}));
      })
      .finally(() => setLoadingBtn(false));
  };

  async function fetchProducts(search) {
    const params = { search };
    return productService.getAll(params).then((res) =>
      res.data.map((item) => ({
        label: item.translation?.title,
        value: item.id,
      }))
    );
  }
  function fetchBrands(search) {
    const params = {
      perPage: pageSize,
      page: pageCurrent,
    };
    return brandService.getAll(params).then((res) =>
      res.data.map((item) => ({
        label: item.translation.title,
        value: item.id,
      }))
    );
  }
  async function fetchCategories(search) {
    const params = { search };
    return categoryService.getAll(params).then((res) =>
      res.data.map((item) => ({
        label: item.translation.title,
        value: item.id,
      }))
    );
  }
  const getInitialValues = () => {
    const data = activeMenu.data;
    if (!activeMenu.data?.start) {
      return data;
    }
    const start = activeMenu.data.start;
    const end = activeMenu.data.end;
    return {
      ...data,
      start: moment(start, 'YYYY-MM-DD'),
      end: moment(end, 'YYYY-MM-DD'),
    };
  };
  // const customField = () => {
  //   if (activeMenu.data?.brands)
  //     return (
  //       <Form.Item
  //         label={t('brands')}
  //         name='brands'
  //         rules={[
  //           {
  //             required: true,
  //             message: t('required'),
  //           },
  //         ]}
  //       >
  //         <DebounceSelect fetchOptions={fetchBrands} mode='multiple' />
  //       </Form.Item>
  //     );
  //   else if (activeMenu.data?.categories)
  //     return (
  //       <Form.Item
  //         label={t('categories')}
  //         name='categories'
  //         rules={[
  //           {
  //             required: true,
  //             message: t('required'),
  //           },
  //         ]}
  //       >
  //         <DebounceSelect fetchOptions={fetchCategories} mode='multiple' />
  //       </Form.Item>
  //     );
  //   else
  //     return (
  //       <Form.Item
  //         label={t('products')}
  //         name='products'
  //         rules={[
  //           {
  //             required: true,
  //             message: t('required'),
  //           },
  //         ]}
  //       >
  //         <DebounceSelect fetchOptions={fetchProducts} mode='multiple' />
  //       </Form.Item>
  //     );
  // };
  const choices = {
    product: (
      <Form.Item
          label={t('products')}
          name='products'
          rules={[
            {
              required: true,
              message: t('required'),
            },
          ]}
        >
          <DebounceSelect fetchOptions={fetchProducts} mode='multiple' />
        </Form.Item>
    ),
    category: (
      <Form.Item
          label={t('categories')}
          name='categories'
          rules={[
            {
              required: true,
              message: t('required'),
            },
          ]}
        >
          <DebounceSelect fetchOptions={fetchCategories} mode='multiple' />
        </Form.Item>
    ),
    brand: (
      <Form.Item
          label={t('brands')}
          name='brands'
          rules={[
            {
              required: true,
              message: t('required'),
            },
          ]}
        >
          <DebounceSelect fetchOptions={fetchBrands} mode='multiple' />
        </Form.Item>
    ),
  };
  const getDiscountType = (discount) => {
    if (!isEmpty(discount.categories)) {
      setType('category');
      
       return  getCategories(discount)
    }
    if (!isEmpty(discount.brands)) {
      setType('brand');

      return  getBrands(discount)
    }
    if (!isEmpty(discount.products)) {
      setType('product');

       return getProducts(discount)
    }
  };
  function formatProducts(data) {
    return data.map((item) => ({
      label: item.translation?.title,
      value: item.id,
    }));
  }
  function getCategories(discount) {
    discountService
      .getById(discount.id)
      .then(({ data }) => {      
        form.setFieldsValue({
          categories: formatProducts(data),
        });
      
      })
      .finally(() => setLoading(false));
  }
  function getBrands(discount) {
    discountService
      .getById(discount.id)
      .then(({ data }) => {      
        form.setFieldsValue({
          brands: formatProducts(data),
        });
      
      })
      .finally(() => setLoading(false));
  }
  function getProducts(discount) {
    discountService
      .getById(discount.id)
      .then(({ data }) => {      
        form.setFieldsValue({
          products: formatProducts(data),
        });
      
      })
      .finally(() => setLoading(false));
  }
  const getDiscount = (alias) => {
    setLoading(true);
    discountService
      .getById(alias)
      .then((res) => {
        let discount = res.data;
        getDiscountType(discount);
      })
      .finally(() => dispatch(disableRefetch(activeMenu)));
  };
  const getDiscountTypee = (value) => {
    setType(value);
  };
  useEffect(() => {
    if (activeMenu.refetch) {
      getDiscount(id);
    }
  }, [activeMenu.refetch]);
  
  return (
    <Card title={t('edit.discount')} className='h-100'>
      {!loading ? (
        <Form
          name='discount-add'
          layout='vertical'
          onFinish={onFinish}
          form={form}
          initialValues={{ ...getInitialValues() }}
          className='d-flex flex-column h-100'
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={t('type')}
                name={'type'}
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <Select>
                  <Select.Option value='fix'>{t('fix')}</Select.Option>
                  <Select.Option value='percent'>{t('percent')}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('price')}
                name='price'
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <InputNumber className='w-100' />
              </Form.Item>
            </Col>
            <Col span={6}>
            <Form.Item label={t('flash_sale')} name='flash_sale' valuePropName='checked'>
              <Switch />
            </Form.Item>
          </Col>
            <Col span={24}>
              <Form.Item
                label={t('image')}
                name='image'
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <ImageUploadSingle
                  form={form}
                  image={image}
                  setImage={setImage}
                  name='image'
                  type='discounts'
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={t('start.date')}
                name='start'
                rules={[
                  {
                    required: true,
                    message: t('requried'),
                  },
                ]}
              >
                <DatePicker
                  className='w-100'
                  placeholder=''
                  disabledDate={(current) =>
                    moment().add(-1, 'days') >= current
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={t('end.date')}
                name='end'
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <DatePicker className='w-100' placeholder='' />
              </Form.Item>
            </Col>
           
            {/* <Col span={24}>
            {customField()}

             </Col> */}
               <Col span={24}>
              <Form.Item
                label={t('by_choices_banner')}
                name='type'
                // rules={[
                //   {
                //     required: true,
                //     message: t('required'),
                //   },
                // ]}
              >
                <Select value={type} onChange={getDiscountTypee}>
                  <Select.Option value='product'>{t('product')}</Select.Option>
                  <Select.Option value='category'>
                    {t('category')}
                  </Select.Option>
                  <Select.Option value='brand'>{t('brand')}</Select.Option>
                </Select>{' '}
              </Form.Item>
            </Col>
            <Col span={24}>{get(choices, type)}</Col>
          </Row>
          <div className='flex-grow-1 d-flex flex-column justify-content-end'>
            <div className='pb-5'>
              <Button type='primary' htmlType='submit' loading={loadingBtn}>
                {t('submit')}
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <Spin size='large' className='py-5' />
        </div>
      )}
    </Card>
  );
}
