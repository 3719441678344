import React, { useContext, useEffect, useState } from 'react';
import '../../assets/scss/components/product-categories.scss';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Image, Space, Table, Tag } from 'antd';
import { IMG_URL } from '../../configs/app-global';
import { Context } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GlobalContainer from '../../components/global-container';
import CustomModal from '../../components/modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addMenu, disableRefetch } from '../../redux/slices/menu';
import categoryService from '../../services/category';
import { fetchCategories } from '../../redux/slices/category';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../components/delete-button';
import { Excel } from 'antd-table-saveas-excel';
import ImportButton from '../../components/import';

const Categories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToEdit = (row) => {
    dispatch(
      addMenu({
        url: `category/${row.uuid}`,
        id: 'category_edit',
        name: t('edit.category'),
      })
    );
    navigate(`/category/${row.uuid}`);
  };

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'translation',
      render: (translation) => translation?.title,
      width: '25%',
    },
    {
      title: t('image'),
      dataIndex: 'img',
      key: 'img',
      render: (img, row) => {
        return (
          <Image
            src={img ? IMG_URL + img : 'https://via.placeholder.com/150'}
            alt='img_gallery'
            width={100}
            className='rounded'
            preview
            placeholder
            key={img + row.id}
          />
        );
      },
    },
    {
      title: t('status'),
      dataIndex: 'active',
      key: 'active',
      render: (active, row) =>
        active ? (
          <Tag color='cyan'> {t('active')}</Tag>
        ) : (
          <Tag color='yellow'>{t('inactive')}</Tag>
        ),
    },
    {
      title: t('child.categories'),
      dataIndex: 'children',
      key: 'children',
      render: (children) => children?.length,
    },
    {
      title: t('options'),
      key: 'options',
      dataIndex: 'options',
      render: (data, row) => {
        return (
          <Space>
            <Button
              type='primary'
              icon={<EditOutlined />}
              onClick={() => goToEdit(row)}
            />
            <DeleteButton
              icon={<DeleteOutlined />}
              onClick={() => {
                setId(row.uuid);
                setType(false);
                setIsModalVisible(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const { setIsModalVisible } = useContext(Context);
  const [id, setId] = useState(null);
  const [alias, setAlias] = useState(false);
  const [type, setType] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { categories, meta, loading } = useSelector(
    (state) => state.category,
    shallowEqual
  );
  const [showImport, setImport] = useState(false);
  console.log(categories, 'hhhhhhhhhhhhh');
  const categoryDelete = () => {
    setLoadingBtn(true);
    categoryService
      .delete(id)
      .then(() => {
        dispatch(fetchCategories({}));
        toast.success(t('successfully.deleted'), {autoClose: 10000});
      })
      .finally(() => {
        setIsModalVisible(false);
        setLoadingBtn(false);
      });
  };

  const handleActive = () => {
    setLoadingBtn(true);
    categoryService
      .setActive(alias)
      .then(() => {
        setIsModalVisible(false);
        dispatch(fetchCategories({}));
        toast.success(t('successfully.updated'), {autoClose: 10000});
      })
      .finally(() => setLoadingBtn(false));
  };
  console.log(categories, 'cccccccccc');
  // excel export
  const dataSend = [
    {
      title: t('id'),
      dataIndex: 'id',
    },
    {
      title: t('name'),
      dataIndex: 'translation',
      render: (translation) => translation.title,
    },
    // {
    //   title: t('name En'),
    //   dataIndex: 'translations',
    //   render: (translations) => translations[1]?.title,

    // },
    {
      title: t('active'),
      dataIndex: 'active',
      render: (active) => {
        if (active === true) return t('active');
        else return t('inactive');
      },
    },
  ];

  const excelExport = () => {
    const excel = new Excel();

    setLoadingBtn(true);
    categoryService
      .getListIndividualy()
      .then(({ data }) => {
        excel
          .addSheet('basic')
          .addColumns(dataSend)
          .addDataSource(data, {
            str2Percent: true,
          })
          .saveAs('categories.xlsx');
      })
      .finally(() => setLoadingBtn(false));
  };

  useEffect(() => {
    if (activeMenu.refetch) {
      dispatch(fetchCategories({}));
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu.refetch]);

  const onChangePagination = (pageNumber) => {
    const { pageSize, current } = pageNumber;
    dispatch(fetchCategories({ perPage: pageSize, page: current }));
  };
  const goToAddProduct = () => {
    dispatch(
      addMenu({
        id: 'categories-add',
        url: 'category/add',
        name: t('add.category'),
      })
    );
    navigate('/category/add');
  };
  return (
    // <GlobalContainer
    //   headerTitle={t('categories')}
    //   navLInkTo={'/category/add'}
    //   buttonTitle={t('add.category')}

    // >
    <Card
      title={t('categories')}
      extra={
        <Space>
          <Button type='primary' onClick={goToAddProduct}>
            {t('add.category')}
          </Button>
          <Button onClick={excelExport}>{t('export')}</Button>
          <Button onClick={() => setImport(true)}>{t('import')}</Button>
        </Space>
      }
    >
      <Table
        columns={columns}
        dataSource={categories}
        // expandable
        pagination={{
          pageSize: meta.per_page,
          page: meta.current_page,
          total: meta.total,
        }}
        rowKey={(record) => record.key}
        onChange={onChangePagination}
        loading={loading}
      />
      <CustomModal
        click={type ? handleActive : categoryDelete}
        text={type ? t('set.active.category') : t('delete.category')}
        loading={loadingBtn}
      />
      <ImportButton
        visible={showImport}
        handleCancel={() => setImport(false)}
        type='category'
      />
    </Card>
  );
};

export default Categories;
