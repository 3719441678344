import request from './request';

const invoiceservice = {
  getAll: (params) =>
    request.get('dashboard/admin/invoices/paginate', { params }),
  getById: (id, params) =>
    request.get(`dashboard/admin/invoices/${id}`, { params }),
  // create: (data) => request.post('dashboard/admin/invoices', data),
  update: (id, data) => request.post(`dashboard/admin/invoices/${id}/status`, data),
  updateStatus: (id, params) =>
    request.post(`dashboard/admin/order/details/${id}/status`, {}, { params }),
 
};

export default invoiceservice;
