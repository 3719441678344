import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Cascader,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import LanguageList from '../../components/language-list';
import TextArea from 'antd/es/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import categoryService from '../../services/category';
import ImageUploadSingle from '../../components/image-upload-single';
import { IMG_URL } from '../../configs/app-global';
import { fetchCategories } from '../../redux/slices/category';
import { useTranslation } from 'react-i18next';
import { AsyncTreeSelect } from '../../components/async-tree-select';

const CategoryEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [form] = Form.useForm();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [error, setError] = useState(null);


  const { uuid } = useParams();
  const { defaultLang, languages } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );
  const {categories} = useSelector(
    (state) => state.category,
    shallowEqual
  );
  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  const createImage = (name) => {
    return {
      name,
      url: IMG_URL + name,
    };
  };

  function getLanguageFields(data) {
    if (!data) {
      return {};
    }
    const { translations } = data;
    const result = languages.map((item) => ({
      [`title[${item.locale}]`]: translations.find(
        (el) => el.locale === item.locale
      )?.title,
      [`description[${item.locale}]`]: translations.find(
        (el) => el.locale === item.locale
      )?.description,
    }));
    return Object.assign({}, ...result);
  }
  const createSelectObject = (parent) => {
    setLoading(true);
    categoryService
      .getById(parent).then((res) => {
        let category = res.data;
        return {
          label: category.translation ? category.translation.title : category.name,
          value: category.id,
        };
        setLoading(false);

      })
   
  };

  const getCategory = (alias) => {
    setLoading(true);
    categoryService
      .getById(alias)
      .then((res) => {
        let category = res.data;
        form.setFieldsValue({
          ...category,
          ...getLanguageFields(category),
          image: createImage(category.img),
          keywords: category.keywords.split(','),
          
          //  parent_id: createSelectObject(category)
        });
        setImage(createImage(category.img));

      })
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  };
 
  async function fetchAllCategories() {
    const params = { perPage: 100 };
    return categoryService.getAll(params).then((res) =>{
      return res.data.map((item) => ({
        title: item.translation?.title,
        value: item.id,
        key: item.id,
        children: item.children?.map((el) => ({
          title: el.translation?.title,
          value: el.id,
          key: el.id,
          children: el.children?.map((v) => ({
            title: v.translation?.title,
            value: v.id,
            key: v.id,
            children: v.children?.map((t) => ({
              title: t.translation?.title,
              value: t.id,
              key: t.id,
          }))
        }))
        // children: recursiveFindChildren(item),
        // children: item.children
      }))
//})
    }))
  })
    
  }
  // var elements = [];
  // var recursiveFindChildren = (el) => {
  //   for (let i = 0, iLength = el.children.length; i < iLength; i++) {
  //     elements.push({
  //       title: el.children[i].translation?.title,
  //       value: el.children[i].id,
  //       key: el.children[i].id,
  //     });

  //     if (el.children[i].children.length) {
  //       recursiveFindChildren(el.children[i]);
  //     }
  //   }
  //   console.log(elements, 'rrrrrrrrrrrrrrrrr');

  //   return elements;
  // };
  
  const onFinish = (values) => {
    console.log(values, 'vaaaaaaaaaaaa');
    setLoadingBtn(true);
    const body = {
      ...values,
      parent_id: values.parent_title?.value,
      type: 1,
      active: values.active ? 1 : 0,
      keywords: values.keywords.join(','),
      'images[0]': image?.name,
    };
    console.log('body', body)
    const nextUrl = 'catalog/categories';
    categoryService
      .update(uuid, body)
      .then(() => {
        toast.success(t('successfully.updated'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        dispatch(fetchCategories());
        navigate(`/${nextUrl}`);
      })
      .catch((err) => setError(err.response.data.params, {autoClose: 10000}))
      .finally(() => setLoadingBtn(false));
  };

  const onFinishFailed = (values) => console.log(values);

  useEffect(() => {
    if (activeMenu.refetch) {
      getCategory(uuid);
    }
    fetchAllCategories();
  }, [activeMenu.refetch]);
  return (
    <Card title={t('edit.category')} /*extra={<LanguageList />}*/>
      {!loading ? (
        <Form
          name='basic'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{ ...activeMenu.data }}
          
          form={form}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={12}>
            {languages.map((item) => (
              <Col span={12}>
                <Form.Item
                  key={'name' + item.id}
                  label={`${t('name')} ${t(item.locale)}`}
                  name={`title[${item.locale}]`}
                  rules={[
                    {
                      required: true,
                      // required: item.locale === defaultLang,
                      message: t('required'),
                    },
                  ]}
                  help={
                    error
                      ? error[`title.${defaultLang}`]
                        ? error[`title.${defaultLang}`][0]
                        : null
                      : null
                  }
                  validateStatus={error ? 'error' : 'success'}
                  // hidden={item.locale !== defaultLang}
                >
                  <Input />
                </Form.Item>
              </Col>
            ))}

            {/* </Col> */}
            {languages.map((item) => (
              <Col span={12}>
                <Form.Item
                  key={'description' + item.id}
                  label={`${t('description')} ${t(item.locale)}`}
                  name={`description[${item.locale}]`}
                  rules={[
                    {
                      required: true /*item.locale === defaultLang*/,
                      message: t('required'),
                    },
                  ]}
                  // hidden={item.locale !== defaultLang}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            ))}

            <Col span={12}>
              <Form.Item
                label={t('keywords')}
                name='keywords'
                rules={[{ required: true, message: t('required') }]}
              >
                <Select mode='tags' style={{ width: '100%' }}></Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t('parent.category')}
                name='parent_title'
                // rules={[{ required: true, message: t('required') }]}
              >
                {/* <Cascader options={[fetchAllCategories]}     
                fieldNames={{ label: 'title', value: 'value', children:"children" }}/> */}

              

                 <AsyncTreeSelect fetchOptions={fetchAllCategories}  /> 

                {/* <Select>
                  <Select.Option value={0}>---</Select.Option>
                  {allCategories.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.translation?.title}
                    </Select.Option>
                  ))}
                </Select> */}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label={t('image')}>
                <ImageUploadSingle
                  type='categories'
                  image={image}
                  setImage={setImage}
                  form={form}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label={t('active')}
                name='active'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit' loading={loadingBtn}>
            {t('submit')}
          </Button>
        </Form>
      ) : (
        <div className='d-flex justify-content-center align-items-center py-5'>
          <Spin size='large' className='mt-5 pt-5' />
        </div>
      )}
    </Card>
  );
};
export default CategoryEdit;
