import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import invoiceService from '../../services/invoice';

const initialState = {
  loading: false,
  invoices: [],
  error: '',
  params: {
    page: 1,
    perPage: 10,
    status: 'new',
  },
  meta: {},
};

export const fetchInvoices = createAsyncThunk(
  'invoice/fetchInvoices',
  (params = {}) => {
    return invoiceService
      .getAll({ ...initialState.params, ...params })
      .then((res) => res);
  }
);
// export const fetchSellerOrders = createAsyncThunk(
//   'order/fetchSellerOrders',
//   (params = {}) => {
//     return sellerinvoiceService
//       .getAll({ ...initialState.params, ...params })
//       .then((res) => res);
//   }
// );


const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchInvoices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInvoices.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.invoices = payload.data;
      state.meta = payload.meta;
      state.params.page = payload.meta.current_page;
      state.params.perPage = payload.meta.per_page;
      state.error = '';
    });
    builder.addCase(fetchInvoices.rejected, (state, action) => {
      state.loading = false;
      state.orders = [];
      state.error = action.error.message;
    });

    // builder.addCase(fetchSellerOrders.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(fetchSellerOrders.fulfilled, (state, action) => {
    //   const { payload } = action;
    //   state.loading = false;
    //   state.orders = payload.data;
    //   state.meta = payload.meta;
    //   state.params.page = payload.meta.current_page;
    //   state.params.perPage = payload.meta.per_page;
    //   state.error = '';
    // });
    // builder.addCase(fetchSellerOrders.rejected, (state, action) => {
    //   state.loading = false;
    //   state.orders = [];
    //   state.error = action.error.message;
    // });
  },
});

export default invoiceSlice.reducer;
