import React, { useEffect, useState } from 'react';
import '../../../assets/scss/components/product-categories.scss';
import { Image, Table } from 'antd';
import { IMG_URL } from '../../../configs/app-global';
import GlobalContainer from '../../../components/global-container';
import categoryService from '../../../services/rest/category';
import { useTranslation } from 'react-i18next';
import getImage from '../../../helpers/getImage';

export default function SellerCategories() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('image'),
      dataIndex: 'img',
      key: 'img',
      render: (img, row) => {
        return (
          <Image
            src={getImage(img)}
            alt='img_gallery'
            width={100}
            className='rounded'
            preview
            placeholder
            key={img + row.id}
          />
        );
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchCategories = () => {
    setLoading(true);
    const params = {
      page: pageCurrent,
      perPage: pageSize,
    };
    categoryService
      .getAll(params)
      .then((res) => {
        const dataInfo = res.data.map((item) => ({
          active: item.active,
          img: item.img,
          name: item.translation !== null ? item.translation.title : 'no name',
          key: item.alias + '_' + item.id,
          alias: item.alias,
          children: item.children.map((child) => ({
            name:
              child.translation !== null ? child.translation.title : 'no name',
            alias: child.alias,
            key: item.alias + '_' + child.id,
            img: child.img,
            active: child.active,
          })),
        }));
        setData(dataInfo);
        setTotal(res.meta.total);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchCategories();
  }, [pageSize, pageCurrent]);

  const onChangePagination = (pageNumber) => {
    setPageSize(pageNumber.pageSize);
    setPageCurrent(pageNumber.current);
  };

  return (
    <GlobalContainer headerTitle={t('categories')}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: pageSize,
          page: pageCurrent,
          total: total,
        }}
        rowKey={(record) => record.key}
        onChange={onChangePagination}
        loading={loading}
      />
    </GlobalContainer>
  );
}
