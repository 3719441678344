import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Switch } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeFromMenu, setMenuData } from '../../redux/slices/menu';
import brandService from '../../services/brand';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBrands } from '../../redux/slices/brand';
import { useTranslation } from 'react-i18next';

const BrandsAdd = () => {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [error, setError] = useState(null);

  const { defaultLang, languages } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  const onFinish = (values) => {
    const body = {
      ...values,
      active: values.active ? 1 : 0,
      'images[0]': image?.name,
    };
    setLoadingBtn(true);
    const nextUrl = 'catalog/brands';
    brandService
      .create(body)
      .then(() => {
        toast.success(t('successfully.created'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchBrands({}));
      }).catch((err) => setError(err.response.data.params))

      .finally(() => setLoadingBtn(false));
  };

  return (
    <Card title={t('add.brand')}>
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        form={form}
        initialValues={{ active: true, ...activeMenu.data }}
      >
        <Row gutter={12}>
        {languages.map((item) => (
                   <Col span={12}>

            <Form.Item
              key={'title' + item.id}
              label={`${t('title')} ${t(item.locale)}`}
              name={`title[${item.locale}]`}
              rules={[
                {
                  required: true,
                  // required: item.locale === defaultLang,
                  message: t('required'),
                },
                
              ]}
              help={
                error
                  ? error[`title.${defaultLang}`]
                    ? error[`title.${defaultLang}`][0]
                    : null
                  : null
              }
              validateStatus={error ? 'error' : 'success'}
              // hidden={item.locale !== defaultLang}
            >
              <Input />
            </Form.Item>
            </Col>
          ))}

          <Col span={6}>
            <Form.Item label={t('image')}>
              <ImageUploadSingle
                type='brands'
                image={image}
                setImage={setImage}
                form={form}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <div className='col-md-12 col-sm-6'>
              <Form.Item
                label={t('active')}
                name='active'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Button type='primary' htmlType='submit' loading={loadingBtn}>
          {t('submit')}
        </Button>
      </Form>
    </Card>
  );
};

export default BrandsAdd;
