/* eslint-disable no-invalid-this */
import React, { useState } from 'react';
import './style.scss';
// import * as XLSX from 'xlsx';
// import {  map, mapValues, trim } from 'lodash';
import { Button, Modal } from 'antd';
// import categoryService from '../../services/category';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// import { fetchCategories } from '../../redux/slices/category';
// import brandService from '../../services/brand';
// import { fetchBrands } from '../../redux/slices/brand';
// import productService from '../../services/product';
import { fetchProducts } from '../../redux/slices/product';
// import { Progress } from 'antd';

const Import = (props) => {
  // const [data, setData] = useState([]);
  // const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState();
  // const [progress, setProgress] = useState(0);
  // const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let upload = null;

  // const handleFile = (e) => {
  //   const file = e.target.files[0];
  //   setFile(file);
  //   console.log(file)
  //   // this.setState({file: file.name})

  //   const reader = new window.FileReader();
  //   const rABS = !!reader.readAsBinaryString;

  //   reader.onload = (e) => {
  //     /* Parse data */
  //     const bstr = e.target.result;
      
  //     const wb = XLSX.read(bstr, {
  //       type: rABS ? 'binary' : 'array',
  //       bookVBA: true,
  //     });
  //     setUploaded(true);
  //     let extract = [];
  //     /* Get first worksheet */
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];
  //     console.log(ws,"wwwwwww")
  //     console.log(ws['!ref'],"mmmmmm")
  //     const range = `A1:R${Math.min(ws['!ref'].split(':')[1].slice(1), 32000)}`;
  //     const data = XLSX.utils.sheet_to_json(ws,{ range });
  //     extract = map(data, (v) => mapValues(v, (d) => trim(d)));
  //     setData(extract);
  //   };

  //   if (rABS) {
  //     reader.readAsBinaryString(file);
  //   } else {
  //     reader.readAsArrayBuffer(file);
  //   }
  // };
 
// ****************************************************************
 const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
const handleFileSelect = (e) => {
  console.log('file :>> ', file);
  sendFile(file);
  props.handleCancel();
}
const sendFile = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  console.log('uploaded file :>> ', file);
  const token = "599|WlDE33aKq3QIjoL2Z861hkXZnGGvekFDBa04amFN";
  fetch('https://apis.elkharatonline.com/api/v1/dashboard/admin/products/import-excel', {
    method: 'POST',
    body: formData,
    headers: {
      // 'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
    }
  })

  .then(response => response.json())
  .then(()=>{
    dispatch(fetchProducts());
    toast.success(t('successfully.created'), {autoClose: 10000});
    // setFile(null)
  })
  .catch((err) =>  toast.error(err.response.data.errors.file[0], {autoClose: 10000})); 
 
}


// **********************************************************
// const handleFileChange = (event) => {
//   setSelectedFile(event.target.files[0]);
// };

// const handleSubmit = (event) => {
//   event.preventDefault();
//   const formData = new FormData();
//   formData.append('file', selectedFile);
//   console.log('selectedFile :>> ', selectedFile);
//   const token = "599|WlDE33aKq3QIjoL2Z861hkXZnGGvekFDBa04amFN";
//   fetch('https://apis.elkharatonline.com/api/v1/dashboard/admin/products/import-excel', {
//     method: 'POST',
//     body: formData,
//     headers: {
//       // 'Content-Type': 'multipart/form-data',
//       'Authorization': `Bearer ${token}`,
//     }
//   })
//   .then(response => response.json())
//   .then(data => console.log(data))
//   .catch(error => console.error(error));
// };
// ****************************************************************
  // const getCategories = () => {
  //   map(data, (d) => {
  //     let body = {
  //       ...d,
  //       active: d.active ? 1 : 0,
  //       type: 1,
  //     };
  //     categoryService.create(body).then(() => {
  //       toast.success(t('successfully.created'));
  //       setFile(null)

  //       dispatch(fetchCategories());
  //       props.handleCancel();
  //     });
  //   }).catch((err) =>  toast.error(err.response.data.params)); 
  //   // categoryService
  //   //   .create(body)
  //   //   .then(() => {
  //   //     toast.success(t('successfully.created'));
  //   //     dispatch(fetchCategories());
  //   //   })

    
  //   // .finally(() => setLoadingBtn(false));
  // };
  // const getBrands = () => {
  //   map(data, (d) => {
  //     let body = {
  //       ...d,
  //       active: d.active ? 1 : 0,
  //       type: 1,
  //     };
  //     brandService.create(body).then(() => {
  //       toast.success(t('successfully.created'));
  //       setFile(null)

  //       dispatch(fetchBrands());
  //       props.handleCancel();
  //     });
  //   }).catch((err) =>  toast.error(err.response.data.params)); 

  // };
  // const getProducts = () => {
   
  //   console.log(data,"data from import")
    
  //   productService.import({products:data}).then(() => {
  //     dispatch(fetchProducts());
  //     // setFile(null)
  //     toast.success(t('successfully.created'));
  //     props.handleCancel();
  //   })
  // .catch((err) =>  toast.error(err.response.data.errors.file[0])); 
  // };
  // const getType = () => {
  //   const { type } = props;
  //   if (type === 'category') getCategories();
  //   if (type === 'brand') getBrands();
  //   if (type === 'product') getProducts();
  // };
  const onCancel = () => {
    setFile(null)
    props.handleCancel();
  };

  return (
    <Modal
      title='Import'
      width='80%'
      style={{ top: 20 }}
      visible={props.visible}
      footer={null}
      closable={false}
      onCancel={props.handleCancel}
      destroyOnClose={true}
    >

      {/* <div className='DivC'>
        <div>
          {' '}
          <button
            onClick={() => {
              upload.click();
            }}
            className='defult'
          >
            Upload
            <input
              type='file'
              id='file'
              ref={(ref) => (upload = ref)}
              style={{ display: 'none' }}
              onChange={(e) => handleFile(e)}
            />
          </button>
          {uploaded && file&& <p>{`${file?.name}  is Uploaded`}</p>}
          {uploaded && file&& <Progress  size="small" style={{ marginTop: '25px', marginBottom: '25px' }} percent={progress} />}

        </div>

        <div className='divsec'>
          <Button style={{marginLeft: 15}} onClick={getType}>Import</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </div> */}
    

      <div className='DivC'>
        <div>
          {' '}
          <button
             onClick={() => {
              upload.click();
            }}
            className='defult'
          >
            Upload
            <input
              type='file'
              id='file'
              ref={(ref) => (upload = ref)}
              style={{ display: 'none' }}
              onChange={(e)=>handleFileChange(e)}
            />
          </button>
          {file && <p>{`${file?.name}  is Uploaded`}</p>}
          {/* {file && <Progress  size="small" style={{ marginTop: '25px', marginBottom: '25px' }} percent={progress} />} */}

        </div>

        <div className='divsec'>
          <Button style={{marginLeft: 15}} onClick={(e)=>handleFileSelect(e)}>Import</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </div>
      {/* <div>

        <input type="file" onChange={handleFileChange} />

        <div>{excelFile && `${excelFile.name}`}</div>

        <button onClick={handleUploadClick}>Upload</button>
    </div> */}
    </Modal>
  );
};
export default Import;
