import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBanners } from '../../redux/slices/banner';
import shopService from '../../services/shop';
import productService from '../../services/product';
import productRestService from '../../services/rest/product';
import { DebounceSelect } from '../../components/search';
import bannerService from '../../services/banner';
import { IMG_URL } from '../../configs/app-global';
import { useTranslation } from 'react-i18next';
import brandService from '../../services/brand';
import categoryService from '../../services/category';
import { get, includes, isEmpty, map } from 'lodash';
import { AsyncTreeSelect } from '../../components/async-tree-select';

const BannerEdit = () => {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { categories } = useSelector((state) => state.category, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [shops, setShops] = useState([]);
  const [brands, setBrands] = useState([]);

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('product');

  const choices = {
    product: (
      <Form.Item
        label={t('products')}
        name='products'
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <DebounceSelect fetchOptions={fetchProducts} mode='multiple' />
      </Form.Item>
    ),
    category: (
      <Form.Item
        label={t('categories')}
        name='categories'
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <AsyncTreeSelect fetchOptions={fetchCategories} multiple />
      </Form.Item>
    ),
    brand: (
      <Form.Item
        label={t('brands')}
        name='brands'
        rules={[
          {
            required: true,
            message: t('required'),
          },
        ]}
      >
        <DebounceSelect fetchOptions={fetchBrands} mode='multiple' />
      </Form.Item>
    ),
  };
  function fetchBrands(username) {
    const params = {
      search: username,
    };
    return brandService.getAll(params).then((res) => {
      setBrands(res.data, '');
      return res.data.map((item) => ({
        label: item.translation.title,
        value: item.id,
      }));
    });
  }
  // async function fetchCategories(search) {
  //   // note diffecult to make it a tree select because in edit i need to check every id in multi level children or 
  //   //we need end point to return me all cat objects by array of cats ids
  //   const params = { search };
  //   return categoryService.getAll(params).then((res) =>
  //     res.data.map((item) => ({
  //       label: item.translation.title,
  //       value: item.id,
  //     }))
  //   );
  // }
  async function fetchCategories() {
    const params = { perPage: 100 };
    return categoryService.getAll(params).then((res) =>{
      return res.data.map((item) => ({
        title: item.translation?.title,
        value: item.id,
        key: item.id,
        children: item.children?.map((el) => ({
          title: el.translation?.title,
          value: el.id,
          key: el.id,
          children: el.children?.map((v) => ({
            title: v.translation?.title,
            value: v.id,
            key: v.id,
            children: v.children?.map((t) => ({
              title: t.translation?.title,
              value: t.id,
              key: t.id,
          }))
        }))
        // children: recursiveFindChildren(item),
        // children: item.children
      }))
//})
    }))
  })
    
  }
  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  const createImage = (name) => {
    return {
      name,
      url: IMG_URL + name,
    };
  };
  const getBannerType = (banner) => {
    if (!isEmpty(banner.categories)) {
      setType('category');
      
       return  getCategories(banner)
    }
    if (!isEmpty(banner.brands)) {
      setType('brand');

      return  getBrands(banner)
    }
    if (!isEmpty(banner.products)) {
      setType('product');

       return getProducts(banner)
    }
  };
  
  function getCategories(banner) {
    bannerService
      .getCategories(banner.id)
      .then(({ data }) => {      
        form.setFieldsValue({
          categories: formatProducts(data),
        });
      
      })
      .finally(() => setLoading(false));
  }
  function getBrands(banner) {
    bannerService
      .getBrands(banner.id)
      .then(({ data }) => {      
        form.setFieldsValue({
          brands: formatProducts(data),
        });
      
      })
      .finally(() => setLoading(false));
  }
  function getProducts(banner) {
    bannerService
      .getProducts(banner.id)
      .then(({ data }) => {      
        form.setFieldsValue({
          products: formatProducts(data),
        });
      
      })
      .finally(() => setLoading(false));
  }

  const getBanner = (alias) => {
    setLoading(true);
    bannerService
      .getById(alias)
      .then((res) => {
        let banner = res.data;
        form.setFieldsValue({
          ...banner,
          image: createImage(banner.img),
        });
        setImage(createImage(banner.img));
        getBannerType(banner);
        // getProducts(banner.products, banner);
      })
      .finally(() => {
        dispatch(disableRefetch(activeMenu))
        setLoading(false)
      }
      );
  };

  const onFinish = (values) => {
    console.log(values,"vvvvvvvvvvvv")
    const body = {
      url: values.url,
      shop_id: values.shop_id,
      'images[0]': image?.name,
      // ...values,
      products: values?.products?.map((item) => item.value),
      brands: values?.brands?.map((item) => item.value),
      categories: values?.categories?.map((item) => item.value),
      // type: type,
    };
    setLoadingBtn(true);
    const nextUrl = 'banners';
    bannerService
      .update(id, body)
      .then(() => {
        toast.success(t('successfully.updated'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchBanners());
      })
      .finally(() => setLoadingBtn(false));
  };

  function fetchProducts(search) {
    const params = {
      search,
      perPage: 10,
    };
    return productService
      .getAll(params)
      .then((res) => formatProducts(res.data));
  }

  function fetchShops() {
    shopService.get().then(({ data }) => setShops(data));
  }

  useEffect(() => {
    if (activeMenu.refetch) {
      getBanner(id);
    }
    fetchShops();
  }, [activeMenu.refetch]);

  function formatProducts(data) {
    return data.map((item) => ({
      label: item.translation?.title,
      value: item.id,
    }));
  }
  function formatCts(data) {
    return data.map((item) => ({
      // title: item.translation?.title,

      label: item.translation?.title,
      value: item.id,
    }));
  }
  const getDiscountType = (value) => {
    setType(value);
  };
  console.log('loading :>> ', loading);
  return (
    <Card title={t('edit.banner')} className='h-100'>
      {!loading ? (
        <Form
          name='banner-add'
          layout='vertical'
          onFinish={onFinish}
          form={form}
          initialValues={{ active: true, ...activeMenu.data }}
          className='d-flex flex-column h-100'
        >
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={t('image')}
                name='image'
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <ImageUploadSingle
                  type='banners'
                  image={image}
                  setImage={setImage}
                  form={form}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('url')} name={'url'}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t('shop')}
                name={'shop_id'}
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <Select>
                  {shops.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.translation?.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('by_choices_banner')}
                name='type'
                // rules={[
                //   {
                //     required: true,
                //     message: t('required'),
                //   },
                // ]}
              >
                <Select value={type} onChange={getDiscountType}>
                  <Select.Option value='product'>{t('product')}</Select.Option>
                  <Select.Option value='category'>
                    {t('category')}
                  </Select.Option>
                  <Select.Option value='brand'>{t('brand')}</Select.Option>
                </Select>{' '}
              </Form.Item>
            </Col>
            <Col span={24}>{get(choices, type)}</Col>
          </Row>
          <div className='flex-grow-1 d-flex flex-column justify-content-end'>
            <div className='pb-5'>
              <Button type='primary' htmlType='submit' loading={loadingBtn}>
                {t('submit')}
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <Spin size='large' className='py-5' />
        </div>
      )}
    </Card>
  );
};

export default BannerEdit;
