import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  Input,
  DatePicker,
  Select,
  Card,
  Button,
  Row,
  Col,
  InputNumber,
} from 'antd';
import LanguageList from '../../components/language-list';
import { Context } from '../../context/context';
import { useNavigate, useParams } from 'react-router-dom';
import CouponService from '../../services/coupon';
import moment from 'moment';
import profileService from '../../services/profile';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeFromMenu } from '../../redux/slices/menu';
import { useTranslation } from 'react-i18next';
import classificationService from '../../services/classification';
import shopService from '../../services/shop';
import { DebounceSelect } from '../../components/search';
const CouponAdd = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { defaultLang, setDefaultLang } = useContext(Context);
  const [names, setNames] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [classes, setClasses] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const [shops, setShops] = useState([]);
  const [type, setType] = useState('');

  console.log('data from coupon', data);

  async function fetchUserShop() {
    return shopService.getAll().then(({ data }) => setShops(data));
  }
  function fetchClasses() {
    classificationService.getAll().then(({ data }) => setClasses(data));
  }

  useEffect(() => {
    fetchClasses();
    fetchUserShop();
  }, []);

  const getCouponType = (data) =>{
    if (data.use_type === "many_times") {
      setType('many_times')
    }
    if (data.use_type === "first_order") {
      setType('first_order')
    }
    if (data.use_type === "one_time") {
      setType('one_time')
    }
  }

  function fetchCoupon() {
    setLoading(true);
    CouponService.getById(id)
      .then(({ data }) => {
        setData(data);
        getCouponType(data)
        let namesArray = names;
        data.translations.forEach((item) => {
          let lang = item.locale;
          namesArray[lang] = item.title;
        });
        form.setFieldsValue({
          title: namesArray[defaultLang],
          expired_at: moment(data.expired_at, 'YYYY-MM-DD'),
          start_at: moment(data.start_at, 'YYYY-MM-DD'),
          classification_id: data?.classification?.id,
        });
      })
      .finally(() => setLoading(false));
  }

  const onChangeLanguage = ({ target: { value } }) => {
    setDefaultLang(value);
    form.setFieldsValue({
      title: names[value],
    });
  };

  const onChangeName = (e) => {
    let namesArray = names;
    namesArray[defaultLang] = e.target.value;
    setNames(namesArray);
  };

  const onFinish = (values) => {
    console.log(values, 'vaaaaaaaaaaa');
    setLoadingBtn(true);
    const params = {
      ...values,
      shop_id: values?.shop_id || shops[0].id,
      expired_at: moment(values.expired_at).format('YYYY-MM-DD'),
      start_at: moment(values.start_at).format('YYYY-MM-DD'),
      classification_id: values.classification_id,
      title: names,
      qty: Number(values.qty),
      price: Number(values.price),
    };
    const nextUrl = 'coupons';
    if (id) {
      CouponService.update(id, params)
        .then((res) => {
          dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
          navigate(`/${nextUrl}`);
          console.log('rescoupon :>> ', res);
        })
        .finally(() => setLoadingBtn(false));
    } else {
      CouponService.create(params)
        .then((res) => {
          dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
          navigate(`/${nextUrl}`);
        })
        .finally(() => setLoadingBtn(false));
    }
  };

  const onFinishFailed = (values) => console.log('Failed:', values);

  useEffect(() => {
    if (id) {
      fetchCoupon();
    }
  }, []);
  const getType = (value) => {
    setType(value);
  };

  console.log(data, 'ghhhh');
  return (
    <Card
      title={id ? t('edit.coupon') : t('add.coupon')}
      // extra={
      //   <LanguageList value={defaultLang} onChangeLanguage={onChangeLanguage} />
      // }
      loading={loading}
    >
      <Form
        form={form}
        name='basic'
        initialValues={{
          ...data,
        }}
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={t('title')}
              name='title'
              rules={[{ required: true, message: t('required') }]}
            >
              <Input onChange={onChangeName} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('name')}
              name='name'
              rules={[{ required: true, message: t('required') }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('type')}
              name='type'
              rules={[{ required: true, message: t('required') }]}
            >
              <Select>
                <Select.Option value='fix'>{t('fix')}</Select.Option>
                <Select.Option value='percent'>{t('percent')}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('customer_classes')}
              name={'classification_id'}
              // rules={[
              //   {
              //     required: true,
              //     message: t('required'),
              //   },
              // ]}
            >
              <Select>
                {classes.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.translation?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('shops')}
              name={'shop_id'}
              defaultValue={shops[0]?.id}
            >
              <Select defaultValue={shops[0]?.id || 1}>
                {shops.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.translation?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='start_at'
              label={t('started.at')}
              rules={[{ required: true, message: t('required') }]}
            >
              <DatePicker
                className='w-100'
                placeholder=''
                disabledDate={(current) => moment().add(-1, 'days') >= current}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='expired_at'
              label={t('expired.at')}
              rules={[{ required: true, message: t('required') }]}
            >
              <DatePicker
                className='w-100'
                placeholder=''
                disabledDate={(current) => moment().add(-1, 'days') >= current}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('quantity')}
              name='qty'
              rules={[{ required: true, message: t('required') }]}
            >
              <InputNumber className='w-100' />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('price')}
              name='price'
              rules={[{ required: true, message: t('required') }]}
            >
              <InputNumber className='w-100' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('min_order_value')}
              name='minimum_order_price'
              rules={[{ required: true, message: t('required') }]}
            >
              <InputNumber className='w-100' />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('max_discount')}
              name='maximum_price_deducted'
              rules={[{ required: true, message: t('required') }]}
            >
              <InputNumber className='w-100' addonAfter='%' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('use.type')}
              name='use_type'
              rules={[{ required: true, message: t('required') }]}
            >
              <Select onChange={getType}>
                <Select.Option value='first_order'>
                  {t('first_order')}
                </Select.Option>
                <Select.Option value='one_time'>{t('one_time')}</Select.Option>
                <Select.Option value='many_times'>
                  {t('many_times')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {type === 'many_times' ? (
            <Col span={12}>
              <Form.Item
                label={t('user_use')}
                name='max_qty_user'
                rules={[{ required: true, message: t('required') }]}
              >
                <InputNumber className='w-100' />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Button type='primary' htmlType='submit' loading={loadingBtn}>
          {t('submit')}
        </Button>
      </Form>
    </Card>
  );
};

export default CouponAdd;
