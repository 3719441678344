import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
  Switch,
  Image
} from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import { fetchInvoices } from '../../redux/slices/invoice';
import invoiceService from '../../services/invoice';
import getTranslationFields from '../../helpers/getTranslationFields';
import createImage from '../../helpers/createImage';
import { useTranslation } from 'react-i18next';
import { data } from '../../configs/menu-config';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only
import moment from 'moment';

export default function BlogEdit() {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { languages, defaultLang } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [image, setImage] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);
  console.log(image, 'oooo');
  const onFinish = (values) => {
    const body = {
      type: 'invoice',
      ...values,
      invoice_date: moment(values.invoice_date, 'YYYY-MM-DD'),


      status: "completed"

      //   title: getTranslationFields(languages, values),
    };
    console.log('body => ', body);
    setLoadingBtn(true);
    const nextUrl = 'invoices';
    invoiceService
      .update(id, body)
      .then(() => {
        toast.success(t('successfully.update'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchInvoices({}));
      })
      .finally(() => setLoadingBtn(false));
  };

  const fetchInvoice = (id) => {
    setLoading(true);
    invoiceService
      .getById(id)
      .then((res) => {
        let invoice = res.data;
        console.log('data', invoice);
        form.setFieldsValue({
          ...invoice,
        });
        console.log(invoice,"iiiiiiiiiiii")
        setImage(createImage(invoice.img));
      })
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  };

  useEffect(() => {
    if (activeMenu.refetch) {
      fetchInvoice(id);
    }
  }, [activeMenu.refetch]);

  const handleCancel = () => {
    const nextUrl = 'invoices';
    dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
    navigate(`/${nextUrl}`);
  };
  const images = [image.url];
  return (
    <Card title={t('edit.invoice')}>
      {!loading ? (
        <Form
          name='invoice-edit'
          layout='vertical'
          onFinish={onFinish}
          form={form}
          initialValues={{
            active: true,
            ...activeMenu.data,
          }}
        >
          <Row>
            <Col span={12}>
            <Image
                    width={70}
                    height='auto'
                    src={image.url}
                    preview
                    placeholder
                    className='rounded'></Image>
              {/* <img
                onClick={() => setOpen(true)}
                src={image.url}
                width={200}
                height={200}
                
                alt=''
              ></img> */}
            </Col>

            {/* {isOpen && (
              <Lightbox
                mainSrc={images[0].url}
                onCloseRequest={() => setOpen(false)}
              />
            )} */}
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={t('invoice_date')}
                name='invoice_date'
                rules={[
                  {
                    required: true,
                    message: t('requried'),
                  },
                ]}
              >
                <DatePicker
                  className='w-100'
                  placeholder=''
                  //   disabledDate={(current) =>
                  //     moment().add(-1, 'days') >= current
                  //   }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
              label={t('price')}
              name='price'
              rules={[{ required: true, message: t('required') }]}
            >
              <InputNumber className='w-100' />
            </Form.Item>
          </Col>
          </Row>
          <Space>
            <Button type='primary' htmlType='submit' loading={loadingBtn}>
              {t('complete')}
            </Button>
            <Button onClick={handleCancel}>{t('cancel')}</Button>
          </Space>
        </Form>
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <Spin size='large' className='py-5' />
        </div>
      )}
    </Card>
  );
}
