import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Spin, Switch } from 'antd';
import { IMG_URL } from '../../configs/app-global';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import brandService from '../../services/brand';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBrands } from '../../redux/slices/brand';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';

const BrandsEdit = () => {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [error, setError] = useState(null);

  const { defaultLang, languages } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  const createImage = (name) => {
    return {
      name,
      url: IMG_URL + name,
    };
  };
  function getLanguageFields(data) {
    if (!data) {
      return {};
    }
    const { translations } = data;
    console.log(translations,"yyyyyyyyyyyyyyy", data)
    const result = languages.map((item) => ({
      [`title[${item.locale}]`]: find(translations,
        (el) => el.locale === item.locale
      )?.title,

    }));
    console.log(result,"rrrrrrrr")
    return Object.assign({}, ...result);
  }

  const fetchBrand = (id) => {
    setLoading(true);
    brandService
      .getById(id)
      .then((res) => {
        let brand = res.data;
        form.setFieldsValue({
          ...brand,
          ...getLanguageFields(brand),

          image: createImage(brand.img),
        });
        setImage(createImage(brand.img));
      })
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  };

  const onFinish = (values) => {
    const body = {
      ...values,
      active: values.active ? 1 : 0,
      'images[0]': image?.name,
    };
    setLoadingBtn(true);
    const nextUrl = 'catalog/brands';
    brandService
      .update(id, body)
      .then(() => {
        toast.success(t('successfully.updated'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchBrands({}));
      })
      .finally(() => setLoadingBtn(false));
  };

  useEffect(() => {
    if (activeMenu.refetch) {
      fetchBrand(id);
    }
  }, [activeMenu.refetch]);

  return (
    <Card title={t('edit.brand')}>
      {!loading ? (
        <Form
          name='basic'
          layout='vertical'
          onFinish={onFinish}
          form={form}
          initialValues={{ ...activeMenu.data }}
        >
          <Row gutter={12}>
          {languages.map((item) => (
                   <Col span={12}>

            <Form.Item
              key={'title' + item.id}
              label={`${t('title')} ${t(item.locale)}`}
              name={`title[${item.locale}]`}
              rules={[
                {
                  required: true,
                  // required: item.locale === defaultLang,
                  message: t('required'),
                },
                
              ]}
              help={
                error
                  ? error[`title.${defaultLang}`]
                    ? error[`title.${defaultLang}`][0]
                    : null
                  : null
              }
              validateStatus={error ? 'error' : 'success'}
              // hidden={item.locale !== defaultLang}
            >
              <Input />
            </Form.Item>
            </Col>
          ))}


            <Col span={6}>
              <Form.Item label={t('image')}>
                <ImageUploadSingle
                  type='brands'
                  image={image}
                  setImage={setImage}
                  form={form}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <div className='col-md-12 col-sm-6'>
                <Form.Item
                  label={t('active')}
                  name='active'
                  valuePropName='checked'
                >
                  <Switch />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit' loading={loadingBtn}>
            {t('submit')}
          </Button>
        </Form>
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <Spin size='large' className='py-5' />
        </div>
      )}
    </Card>
  );
};

export default BrandsEdit;
