import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputNumber, Row, Space, Input } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import extraService from '../../../services/seller/extra';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import productService from '../../../services/seller/product';
import { setMenuData } from '../../../redux/slices/menu';
import { AsyncSelect } from '../../../components/async-select';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ProductStock = ({ prev, next }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { extraGroups } = useSelector(
    (state) => state.extraGroup,
    shallowEqual
  );
  const [loadingBtn, setLoadingBtn] = useState(null);

  const onFinish = (values) => {
    setLoadingBtn(true);
    const { stocks } = values;
    let extras;
    const isProductWithExtras = !!activeMenu.data?.extras?.length;

    if (isProductWithExtras) {
      extras = stocks.map((item) => ({
        price: item.price,
        price_after_discount: item.price_after_discount,

        quantity: item.quantity,
        id: activeMenu.data?.extras.map(
          (el, idx) => item[`extras[${idx}]`]?.value
        ),
      }));
    } else {
      extras = [
        {
          price: stocks[0].price,
          quantity: stocks[0].quantity,
          price_after_discount: stocks[0].price_after_discount,

        },
      ];
    }

    productService
      .stocks(uuid, { extras })
      .then(() => {
        dispatch(
          setMenuData({
            activeMenu,
            data: { ...activeMenu.data, stocks },
          })
        );
        next();
      })
      .finally(() => setLoadingBtn(false));
  };

  useEffect(() => {
    form.setFieldsValue({
      stocks: activeMenu.data?.stocks?.length ? activeMenu.data?.stocks : [''],
    });
  }, []);

  function addToMenu() {
    const extras = form.getFieldsValue(true);
    dispatch(
      setMenuData({
        activeMenu,
        data: { ...activeMenu.data, stocks: extras.stocks },
      })
    );
  }

  function getExtrasName(extrasId) {
    const extraItem = extraGroups.find((item) => item.id === extrasId);
    return extraItem?.translation?.title;
  }

  function fetchExtra(id) {
    return extraService.getValueByGroupId(id).then((res) =>
      res.data.map((item) => ({
        label: item.value,
        value: item.id,
      }))
    );
  }

  return (
    <Form layout='vertical' form={form} onFinish={onFinish}>
      <Form.List name='stocks'>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  gutter={12}
                  align='middle'
                  style={{ flexWrap: 'nowrap', overflowX: 'auto' }}
                  hidden={!activeMenu.data?.extras?.length && field.key}
                >
                  <Col>
                    <Form.Item
                      label={t('price')}
                      name={[index, 'price']}
                      rules={[{ required: true, message: t('requried') }]}
                    >
                      <InputNumber
                        className='w-100'
                        style={{ minWidth: 200 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label={t('price_after_discount')}
                      name={[index, 'price_after_discount']}
                      rules={[{ required: true, message: t('required') }]}
                    >
                      <InputNumber
                        className='w-100'
                        style={{ minWidth: 200 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label={t('quantity')}
                      name={[index, 'quantity']}
                      rules={[{ required: true, message: t('required') }]}
                    >
                      <InputNumber
                        className='w-100'
                        style={{ minWidth: 200 }}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col>
                    <Form.Item
                      label={t('barcode')}
                      name='barcode'
                      rules={[{ required: true, message: t('required') }]}
                    >
                      <Input className='w-100' />
                    </Form.Item>
                  </Col> */}
                  {activeMenu.data?.extras?.map((item, idx) => (
                    <Col key={'extra' + item}>
                      <Form.Item
                        label={getExtrasName(item)}
                        name={[index, `extras[${idx}]`]}
                        rules={[{ required: true, message: t('required') }]}
                      >
                        <AsyncSelect
                          fetchOptions={() => fetchExtra(item)}
                          className='w-100'
                          style={{ minWidth: 200 }}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                  <Col>
                    {field.key ? (
                      <Button
                        type='primary'
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => remove(field.name)}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              ))}
              {activeMenu.data?.extras?.length ? (
                <Button
                  type='dashed'
                  style={{ width: '100%' }}
                  onClick={() => {
                    add();
                    addToMenu();
                  }}
                >
                  <Space>
                    <PlusOutlined />
                    {t('add')}
                  </Space>
                </Button>
              ) : (
                ''
              )}
            </div>
          );
        }}
      </Form.List>
      <Space className='mt-4'>
        <Button onClick={prev}>{t('prev')}</Button>
        <Button type='primary' htmlType='submit' loading={!!loadingBtn}>
          {t('next')}
        </Button>
      </Space>
    </Form>
  );
};

export default ProductStock;
