import React, { useEffect, useState } from 'react';
import { Button, Col, Descriptions, Row, Space, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeFromMenu } from '../../redux/slices/menu';
import { fetchProducts } from '../../redux/slices/product';
import extraService from '../../services/extra';
import { useTranslation } from 'react-i18next';

const ProductFinish = ({ prev }) => {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { defaultLang } = useSelector((state) => state.formLang, shallowEqual);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = activeMenu.data;
  const [extraValues, setExtraValues] = useState([]);
  const [loading, setLoading] = useState(null);

  function fetchExtraValues() {
    setLoading(true);
    extraService
      .getAllValues()
      .then(({ data }) => setExtraValues(data))
      .finally(() => setLoading(false));
  }

  function finish() {
    const nextUrl = 'catalog/products';
    dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
    dispatch(fetchProducts());
    navigate(`/${nextUrl}`);
  }

  useEffect(() => {
    fetchExtraValues();
  }, []);

  return !loading ? (
    <>
      <Descriptions title={t('product.info')} bordered>
        <Descriptions.Item label={`${t('title')} (${defaultLang})`} span={3}>
          {data[`title[${defaultLang}]`]}
        </Descriptions.Item>
        <Descriptions.Item
          label={`${t('description')} (${defaultLang})`}
          span={3}
        >
          {data[`description[${defaultLang}]`]}
        </Descriptions.Item>
        <Descriptions.Item label={t('shop')} span={1.5}>
          {data.shop?.label}
        </Descriptions.Item>
        <Descriptions.Item label={t('category')} span={1.5}>
          {data.category?.label}
        </Descriptions.Item>
        <Descriptions.Item label={t('brand')} span={1.5}>
          {data.brand?.label}
        </Descriptions.Item>
        <Descriptions.Item label={t('unit')} span={1.5}>
          {data.unit?.label}
        </Descriptions.Item>
        <Descriptions.Item label={t('images')} span={3}>
          <Row gutter={12}>
            {data?.images?.map((item, idx) => (
              <Col key={'image' + idx}>
                <img width={80} alt='product' src={item.url} />
              </Col>
            ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label={t('tax')}>{data.tax}</Descriptions.Item>
        <Descriptions.Item label={t('min.quantity')}>
          {data.min_qty}
        </Descriptions.Item>
        <Descriptions.Item label={t('max.quantity')}>
          {data.max_qty}
        </Descriptions.Item>
      </Descriptions>
      {data.stocks?.map((item, idx) => {
        if (!item) {
          return '';
        }
        return (
          <Descriptions key={'desc' + idx} bordered className='mt-4'>
            <Descriptions.Item label={t('price')} span={2}>
              {item.price}
            </Descriptions.Item>
            <Descriptions.Item label={t('quantity')} span={2}>
              {item.quantity}
            </Descriptions.Item>
            {data.extras.map((extra, idx) => {
              const extraValue = extraValues.find(
                (el) => el.id === item[`extras[${idx}]`]
              );
              return (
                <Descriptions.Item
                  key={'extra' + extra}
                  label={extraValue?.group?.translation?.title}
                >
                  {extraValue?.value}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        );
      })}
      <div className='d-flex justify-content-end mt-4'>
        <Space>
          <Button onClick={prev}>{t('prev')}</Button>
          <Button type='primary' onClick={finish}>
            {t('finish')}
          </Button>
        </Space>
      </div>
    </>
  ) : (
    <div className='d-flex justify-content-center align-items-center'>
      <Spin size='large' className='py-5' />
    </div>
  );
};

export default ProductFinish;
