import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Input, Row, Select, Switch } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LanguageList from '../../components/language-list';
import TextArea from 'antd/es/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeFromMenu, setMenuData } from '../../redux/slices/menu';
import categoryService from '../../services/category';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchCategories } from '../../redux/slices/category';
import { useTranslation } from 'react-i18next';
import { AsyncTreeSelect } from '../../components/async-tree-select';

const CategoryAdd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [form] = Form.useForm();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [error, setError] = useState(null);

  const { defaultLang, languages } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  async function fetchAllCategories() {
    const params = { perPage: 100 };
    return categoryService.getAll(params).then((res) =>{
      return res.data.map((item) => ({
        title: item.translation?.title,
        value: item.id,
        key: item.id,
        children: item.children?.map((el) => ({
          title: el.translation?.title,
          value: el.id,
          key: el.id,
          children: el.children?.map((v) => ({
            title: v.translation?.title,
            value: v.id,
            key: v.id,
            children: v.children?.map((t) => ({
              title: t.translation?.title,
              value: t.id,
              key: t.id,
          }))
        }))
        // children: recursiveFindChildren(item),
        // children: item.children
      }))
//})
    }))})
    
  }
  console.log(allCategories,"cccccccccc")
  const onFinish = (values) => {
    setLoadingBtn(true);
    const body = {
      ...values,
      type: 1,
      active: values.active ? 1 : 0,
      keywords: values.keywords.join(','),
      'images[0]': image?.name,
      parent_id: values?.parent_id?.value,

    };
    const nextUrl = 'catalog/categories';
    categoryService
      .create(body)
      .then(() => {
        toast.success(t('successfully.created'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        dispatch(fetchCategories());
        navigate(`/${nextUrl}`);
      })
      .catch((err) => setError(err.response.data.params, {autoClose: 10000}))
      .finally(() => setLoadingBtn(false));
  };

  const onFinishFailed = (values) => console.log(values);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  return (
    <Card title={t('add.category')} /*extra={<LanguageList />}*/>
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{  active: true, ...activeMenu.data }}
        form={form}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={12}>
        {languages.map((item) => (
                   <Col span={12}>

            <Form.Item
              key={'name' + item.id}
              label={`${t('name')} ${t(item.locale)}`}
              name={`title[${item.locale}]`}
              rules={[
                {
                  required: true,
                  // required: item.locale === defaultLang,
                  message: t('required'),
                },
                
              ]}
              help={
                error
                  ? error[`title.${defaultLang}`]
                    ? error[`title.${defaultLang}`][0]
                    : null
                  : null
              }
              validateStatus={error ? 'error' : 'success'}
              // hidden={item.locale !== defaultLang}
            >
              <Input />
            </Form.Item>
            </Col>
          ))}

        {/* </Col> */}
          {languages.map((item) => (
                    <Col span={12}>

            <Form.Item
              key={'description' + item.id}
              label={`${t('description')} ${t(item.locale)}`}
              name={`description[${item.locale}]`}
              
              rules={[
                {
                  required: true/*item.locale === defaultLang*/,
                  message: t('required'),
                },
              ]}
              // hidden={item.locale !== defaultLang}
            >
              <TextArea rows={3} />
            </Form.Item>
            </Col>

          ))}

          <Col span={12}>
            <Form.Item
              label={t('keywords')}
              name='keywords'
              rules={[{ required: true, message: t('required') }]}
            >
              <Select mode='tags' style={{ width: '100%' }}></Select>
            </Form.Item>
          </Col>

          <Col span={12}>
              <Form.Item
                label={t('parent.category')}
                name='parent_id'
                // rules={[{ required: true, message: t('required') }]}
              >
                            <AsyncTreeSelect fetchOptions={fetchAllCategories}  />

                {/* <Select>
                  <Select.Option value={0}>---</Select.Option>
                  {allCategories.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.translation?.title}
                    </Select.Option>
                  ))}
                </Select> */}
              </Form.Item>
            </Col>
          <Col span={4}>
            <Form.Item label={t('image')}>
              <ImageUploadSingle
                type='categories'
                image={image}
                setImage={setImage}
                form={form}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              label={t('active')}
              name='active'
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Button type='primary' htmlType='submit' loading={loadingBtn}>
          {t('submit')}
        </Button>
      </Form>
    </Card>
  );
};
export default CategoryAdd;
