import React, { useEffect, useState } from 'react';
import { Button, Form, Card, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../assets/scss/components/shops-add.scss';
import ClassificationsAddData from './classification-add-data';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import LanguageList from '../../components/language-list';
import classificationService from '../../services/classification';
import { fetchClassifications } from '../../redux/slices/classification';
import { useTranslation } from 'react-i18next';
import getDefaultLocation from '../../helpers/getDefaultLocation';

const ClassificationsAdd = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { languages } = useSelector((state) => state.formLang, shallowEqual);
  const { settings } = useSelector(
    (state) => state.globalSettings,
    shallowEqual
  );

  const [location, setLocation] = useState(getDefaultLocation(settings));
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);

      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  function getLanguageFields(data) {
    if (!data) {
      return {};
    }
    const { translations } = data;
    const result = languages.map((item) => ({
      [`title[${item.locale}]`]: translations.find(
        (el) => el.locale === item.locale
      )?.title,
     
    }));
    return Object.assign({}, ...result);
  }


  const fetchClassification = (uuid) => {
    setLoading(true);
    classificationService
      .getById(uuid)
      .then(({ data }) => {
        form.setFieldsValue({
          ...data,
          ...getLanguageFields(data),
        
          // user: {
          //   value: data.seller.id,
          //   label: data.seller.firstname + ' ' + data.seller.lastname,
          // },
          recommended: data.mark === 'recommended',
        });
        // setBackImage(createImage(data.background_img));
        // setLogoImage(createImage(data.logo_img));
   
      })
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  };

  const onFinish = (values) => {
    setLoadingBtn(true);
    const body = {
      ...values,
      active: values.active? 1:0
    };

    const nextUrl = 'classifications';
    if (!uuid) {
      classificationService
        .create(body)
        .then(() => {
          toast.success('Successfully created', {autoClose: 10000});
          dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
          navigate(`/${nextUrl}`);
          dispatch(fetchClassifications());
        })
        .finally(() => setLoadingBtn(false));
    } else {
      classificationService
        .update(uuid, body)
        .then(() => {
          toast.success('Successfully created', {autoClose: 10000});
          dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
          navigate(`/${nextUrl}`);
          dispatch(fetchClassifications());
        })
        .finally(() => setLoadingBtn(false));
    }
  };

  useEffect(() => {
    if (activeMenu?.refetch && uuid) {
      fetchClassification(uuid);
    }
  }, [activeMenu?.refetch]);

  const handleCancel = () => {
    const nextUrl = 'classifications';
    dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
    navigate(`/${nextUrl}`);
  };

  
  return (
    <Card
      title={uuid ? t('edit.classification') : t('add.classification')}
      extra={<LanguageList />}
      loading={loading}
    >
      <Form
        form={form}
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{
          visibility: true,
          status: 'new',
          ...activeMenu.data,
        }}
      >
        <ClassificationsAddData
        />
        <Space>
          <Button type='primary' htmlType='submit' loading={loadingBtn}>
            {t('save')}
          </Button>
          <Button onClick={handleCancel}>{t('cancel')}</Button>
        </Space>
      </Form>
    </Card>
  );
};
export default ClassificationsAdd;
