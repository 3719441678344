import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Select, Switch } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeFromMenu, setMenuData } from '../../redux/slices/menu';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBanners } from '../../redux/slices/banner';
import shopService from '../../services/shop';
import productService from '../../services/product';
import { DebounceSelect } from '../../components/search';
import bannerService from '../../services/banner';
import { useTranslation } from 'react-i18next';
import brandService from '../../services/brand';
import categoryService from '../../services/category';
import { get } from 'lodash';
import { AsyncTreeSelect } from '../../components/async-tree-select';

const BannerAdd = () => {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [shops, setShops] = useState([]);
  const [type, setType] = useState("");

const  choices = {
  product: <Form.Item
label={t('products')}
name='products'
rules={[
  {
    required: true,
    message: t('required'),
  },
]}
>
<DebounceSelect fetchOptions={fetchProducts} mode='multiple' />
</Form.Item>,
category:  <Form.Item
label={t('categories')}
name='categories'
rules={[
  {
    required: true,
    message: t('required'),
  },
]}
>
<AsyncTreeSelect fetchOptions={fetchCategories} multiple />
</Form.Item>,
brand:  <Form.Item
label={t('brands')}
name='brands'
rules={[
  {
    required: true,
    message: t('required'),
  },
]}
>
<DebounceSelect fetchOptions={fetchBrands} mode='multiple' />
</Form.Item>
}
  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  const onFinish = (values) => {
    const body = {
      url: values.url,
      shop_id: values.shop_id,
      'images[0]': image?.name,
      // ...Object.assign(
      //   {},
      //   ...values.products.map((item, index) => ({
      //     [`products[${index}]`]: item.value,
      //   }))
     // ),
        products: values?.products?.map((item) => item.value),
      brands: values?.brands?.map((item) => item.value),
      categories: values?.categories?.map((item) => item.value),
      type: type
   
      
    };
    setLoadingBtn(true);
    const nextUrl = 'banners';
    bannerService
      .create(body)
      .then(() => {
        toast.success(t('successfully.created'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchBanners());
      })
      .finally(() => setLoadingBtn(false));
  };

 
  async function fetchProducts(search) {
    const params = { search };
    return productService.getAll(params).then((res) =>
      res.data.map((item) => ({
        label: item.translation?.title,
        value: item.id,
      }))
    );
  }
  function fetchBrands(username) {
    const params = {
      search: username,
    };
    return brandService.getAll(params).then((res) =>
      res.data.map((item) => ({
        label: item.translation.title,
        value: item.id,
      }))
    );
  }
  async function fetchCategories() {
    const params = { perPage: 100 };
    return categoryService.getAll(params).then((res) =>{
      return res.data.map((item) => ({
        title: item.translation?.title,
        value: item.id,
        key: item.id,
        children: item.children?.map((el) => ({
          title: el.translation?.title,
          value: el.id,
          key: el.id,
          children: el.children?.map((v) => ({
            title: v.translation?.title,
            value: v.id,
            key: v.id,
            children: v.children?.map((t) => ({
              title: t.translation?.title,
              value: t.id,
              key: t.id,
          }))
        }))
        // children: recursiveFindChildren(item),
        // children: item.children
      }))
//})
    }))
  })
    
  }
 const getDiscountType = (value) => {
    setType(value)
  }; 
  function fetchShops() {
    shopService.get().then(({ data }) => setShops(data));
  }

  useEffect(() => {
    fetchShops();
  }, []);

  function formatProducts(data) {
    return data.map((item) => ({
      label: item.translation?.title,
      value: item.id,
    }));
  }

  return (
    <Card title={t('add.banner')} className='h-100'>
      <Form
        name='banner-add'
        layout='vertical'
        onFinish={onFinish}
        form={form}
        initialValues={{ active: true, ...activeMenu.data }}
        className='d-flex flex-column h-100'
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              label={t('image')}
              name='image'
              rules={[
                {
                  required: true,
                  message: t('required'),
                },
              ]}
            >
              <ImageUploadSingle
                type='banners'
                image={image}
                setImage={setImage}
                form={form}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={t('url')} name={'url'}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('shop')}
              name={'shop_id'}
              rules={[
                {
                  required: true,
                  message: t('required'),
                },
              ]}
            >
              <Select>
                {shops.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.translation?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('by_choices_banner')}
              // name='by'
              rules={[
                {
                  required: true,
                  message: t('required'),
                },
              ]}
            >
               <Select onChange={getDiscountType}>
                <Select.Option value='product'>{t('product')}</Select.Option>
                <Select.Option value='category'>{t('category')}</Select.Option>
                <Select.Option value='brand'>{t('brand')}</Select.Option>

              </Select>            </Form.Item>
          </Col>
          <Col span={24}>
          {get(choices,type)}
          </Col>
          {/* <Col span={24}>
            <Form.Item
              label={t('products')}
              name={'products'}
              rules={[
                {
                  required: true,
                  message: t('required'),
                },
              ]}
            >
              <DebounceSelect
                mode='multiple'
                fetchOptions={fetchProducts}
                debounceTimeout={200}
              />
            </Form.Item>
          </Col> */}
        </Row>
        <div className='flex-grow-1 d-flex flex-column justify-content-end'>
          <div className='pb-5'>
            <Button type='primary' htmlType='submit' loading={loadingBtn}>
              {t('submit')}
            </Button>
          </div>
        </div>
      </Form>
    </Card>
  );
};

export default BannerAdd;
