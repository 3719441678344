import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Select , DatePicker, InputNumber} from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import invoiceService from '../../services/invoice';
import { setRefetch } from '../../redux/slices/menu';

import moment from 'moment';
import { toast } from 'react-toastify';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import { useNavigate } from 'react-router-dom';
import { fetchInvoices } from '../../redux/slices/invoice';

export default function OrderStatusModal(props) {
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const {id, setModal, modal} = props
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();

  

  const handleOk = () => {
    console.log('ok');
  };
  const handleCancel = () => {
   setModal(false)
   form.resetFields()

  };
  const onFinish = (values) => {
    const body = {
      type: 'invoice',
      price: values.price,
      invoice_date: new Date(values.invoice_date).toISOString().slice(0, 10),


      status: "completed"

      //   title: getTranslationFields(languages, values),
    };
    console.log('body => ', body);
    setLoadingBtn(true);
    const nextUrl = 'invoices';
    invoiceService
      .update(props.id, body)
      .then(() => {
        toast.success(t('successfully.change_status'), {autoClose: 10000});
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
      
        dispatch(fetchInvoices({}));
        form.resetFields()
        setModal(false)
      })
      .finally(() => setLoadingBtn(false));
  };


  return (
    <Modal
      visible={props.modal}
      title={`Invoice ${props.id}`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button type='primary' onClick={() => form.submit()} loading={loading}>
          {t('save')}
        </Button>,
        <Button type='default' onClick={handleCancel}>
          {t('cancel')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        // initialValues={{ status: data.status }} 
      >
           <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={t('invoice_date')}
                name='invoice_date'
                rules={[
                  {
                    required: true,
                    message: t('requried'),
                  },
                ]}
              >
                <DatePicker
                  className='w-100'
                  placeholder=''
                  //   disabledDate={(current) =>
                  //     moment().add(-1, 'days') >= current
                  //   }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
              label={t('price')}
              name='price'
              rules={[{ required: true, message: t('required') }]}
            >
              <InputNumber className='w-100' />
            </Form.Item>
          </Col>
          </Row>
      </Form>
    </Modal>
  );
}
