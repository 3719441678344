import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  languages: [],
  defaultLang: 'en',
};

const FormLang = createSlice({
  name: 'formLang',
  initialState,
  reducers: {
    setLangugages(state, action) {
      state.languages = action.payload;
    },
    clearLangugages(state, action) {
      state.languages = [];
    },
    setDefaultLanguage(state, action) {
      state.defaultLang = action.payload;
    },
  },
});

export const { setLangugages, setDefaultLanguage, clearLangugages } =
  FormLang.actions;
export default FormLang.reducer;
