/* eslint-disable no-invalid-this */
import React, { useState } from 'react';
// import axios from 'axios';
import './style.scss';
// import * as XLSX from 'xlsx';
// import { get, map, mapValues, trim } from 'lodash';
import { Button, Modal } from 'antd';
// import categoryService from '../../services/category';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// import { fetchCategories } from '../../redux/slices/category';
// import brandService from '../../services/brand';
// import { fetchBrands } from '../../redux/slices/brand';
// import productService from '../../services/product';
import { fetchProducts } from '../../redux/slices/product';
// import {message} from 'antd'
const Edite = (props) => {
  // const [data, setData] = useState([]);
  // const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let upload = null;

  // ****************************************************************
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleFileSelect = (e) => {
    console.log('file :>> ', file);
    sendFile(file);
    props.handleCancel();
  };
  const sendFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    console.log('uploaded file :>> ', file);
    const token = '599|WlDE33aKq3QIjoL2Z861hkXZnGGvekFDBa04amFN';
    fetch(
      'https://apis.elkharatonline.com/api/v1/dashboard/admin/products/update-excel',
      {
        method: 'POST',
        body: formData,
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then(() => {
        dispatch(fetchProducts());
        toast.success('successfully Edit', {autoClose: 10000});
        // setFile(null)
      })
      .catch((err) => {
        toast.error('Faild Importing The File', {autoClose: 10000})
        // console.log('error :>> ', err.response);
      });
  };

  // **********************************************************

  // const handleFile = (e) => {
  //   const file = e.target.files[0];
  //   setFile(file);
  //   console.log(file)
  //   const reader = new window.FileReader();
  //   const rABS = !!reader.readAsBinaryString;

  //   reader.onload = (e) => {
  //     /* Parse data */
  //     const bstr = e.target.result;

  //     const wb = XLSX.read(bstr, {
  //       type: rABS ? 'binary' : 'array',
  //       bookVBA: true,
  //     });
  //     setUploaded(true);
  //     let extract = [];
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];
  //     console.log(ws,"wwwwwww")
  //     const range = `A1:R${Math.min(ws['!ref'].split(':')[1].slice(1), 32000)}`;
  //     const data = XLSX.utils.sheet_to_json(ws,{ range });
  //     extract = map(data, (v) => mapValues(v, (d) => trim(d)));
  //     setData(extract);

  //   };

  //   if (rABS) {
  //     reader.readAsBinaryString(file);
  //   } else {
  //     reader.readAsArrayBuffer(file);
  //   }
  // };

  // const getProducts = () => {

  //    console.log(data,"data from edite")
  //   productService.edite({products:data}).then(() => {
  //     dispatch(fetchProducts());
  //     toast.success(t('successfully.created'));
  //     props.handleCancel();
  //   })
  // .catch((err) =>  toast.error(err.response.data.errors.file[0]));
  // };

  const onCancel = () => {
    setFile(null);
    props.handleCancel();
  };

  return (
    <Modal
      title='Edit'
      width='80%'
      style={{ top: 20 }}
      visible={props.visible}
      footer={null}
      closable={false}
      onCancel={props.handleCancel}
      destroyOnClose={true}
    >
      <div className='DivC'>
        <div>
          {' '}
          <button
            onClick={() => {
              upload.click();
            }}
            className='defult'
          >
            Upload
            <input
              type='file'
              id='file'
              ref={(ref) => (upload = ref)}
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e)}
            />
          </button>
          {file && <p>{`${file?.name}  is Uploaded`}</p>}
        </div>

        <div className='divsec'>
          <Button
            style={{ marginLeft: 15 }}
            onClick={(e) => handleFileSelect(e)}
          >
            Import
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};
export default Edite;
